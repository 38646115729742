.headContainer {
  display: flex;

  padding: 1rem 1.5rem;
  background-color: #000000;
  color: #ffffff;
  justify-content: space-between;
  /* width:max-content; */
}
.headContainer header {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Questrial", sans-serif;
  font-size: 2rem;
}
.headContainer a{
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: .2rem;
    padding: .2rem .8rem;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    font-weight: normal;

}
.headContainer a:hover{
    /* border: solid; */
    border-color: #ffffff;
    background-color: #3a3a3a;
    color: #ffffff;
}

@media(max-width:700px)
{
.headContainer header{
  font-size: 1.2rem;
}
.headContainer a{
  font-size: .8rem;
  padding: .2rem .5rem;
}
}
