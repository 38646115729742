.footerContainer{
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    
}
footer{
    display: flex;
    text-decoration: none;
    padding: 1rem;
}