.homeContainer {
  display: flex;
  justify-content: center;
  padding: 4rem;
  
}
.homeDiv {
  display: flex;
  flex-direction: column;
  width: 50vw;
}
header {
  display: flex;
  justify-content: left;
  font-weight: bold;
  font-size: 2.5rem;
  color: #1a202c;
}
.homeContent {
  margin-top: 1rem;
}
h1 {
  font-size: 1.3rem;
  color: #2d3748;
}
ul{
  padding-left: 2rem;
}
li {
  font-size: 1rem;
  color: #535f72;
  margin-bottom: .8rem;
}
a {
  font-weight: bolder;
  color: #6b46c1;
}
.listLinks{
  font-size: 1rem;
  color: #535f72;
  font-weight: normal;
}

@media(max-width: 1200px){
  .homeContainer{
    padding: 1.5rem;
  }
  .homeDiv{
    width: 95vw;
  }
  /*
  header{
    font-size: 2rem;
    
  }
  h1{
    font-size: 1rem;
  }
  li{
    font-size: .8rem;
  }
  .listLinks{
    font-size: .8rem;
  } */

}
